
import { File } from '@/interfaces/file.interface';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'FileViewerOffice',

  props: {
    item: Object as PropType<File>,
    visible: Boolean as PropType<boolean>,
    fileUrl: String,
  },

  computed: {
    iFrameUrl(): string {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.fileUrl)}`;
    },
  },
});
